<template>
  <span
    @drop.stop="onDrop"
    @dragover.prevent="$emit('dragOver')"
    @dragenter.prevent="$emit('dragEnter')"
    @dragleave.prevent="$emit('dragLeave')"
  >
    <slot/>
  </span>
</template>

<script>
export default {
  methods: {
    onDrop (e) {
      if ( !e.dataTransfer.getData('pragmaticOrigin') || (this.pragmaticOrigin != 'default' && e.dataTransfer.getData('pragmaticOrigin') != this.pragmaticOrigin) ){
        return
      }
      let transferData = JSON.parse(e.dataTransfer.getData('payload'))
      transferData.raw = e;
      
      
      this.$emit('drop', transferData)
    }
  },
  props: {
    pragmaticOrigin: {
      type: String,
      default: undefined 
    },
  },
}
</script>

<style lang="css" scoped>
</style>