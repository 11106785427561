<template>
  <div>
    <button class="mb-3" @click="versionKey = versionKey+1; init()">REFRESjJ</button>
    
    <b-tabs :key="'sadasdsadasdasdas'+versionKey">
      <b-tab title="test 1">
        <div class="container position-relative">

          <div class="p-5 m-5">
            <div class="burst-bubble" v-for="i in 5"/>

            <div class="color-block2 cursor-pointer" v-for="i in 30" :data-x="120" @click="initAnimation()"/>

            <div class="mt-5"></div>

            <div class="loading-octagon-container">
              <div v-for="i in 10" class="loading-octagon-trail">
                <span/>
              </div>
            </div>

            <div class="expanding d-inline-block">
              <h4 class="d-inline-block">Zoinks</h4>
            </div>
            <div class="banana-spin">BANANA</div>
          </div>

          <b-skeleton class="mb-5"/>

          <div>====================</div>
          <div @click="fuckEverything()">@click="quebrarTudo()"</div>
          <div>====================</div>
          
          <div class="bg-favorite mt-3">
            <div class="my-1" v-for="i in 10">
              <b-collapse accordion="seqsso" :visible="true">salada gamer</b-collapse>
            </div>
          </div>

        </div>
      </b-tab>
      <b-tab title="test image request" active>

        <div class="d-flex align-items-center mt-3">
          <b-form-file v-model="testFile" class="w-25 mr-1"/>
          <b-button variant="success" @click="submitTestFile()">
            Submit
          </b-button>
        </div>

      </b-tab>
      <b-tab title="test 2">
        <div class="container position-relative">

          <b-button @click="animationTest2()" variant="purple" class="m-2">move</b-button>

          <div class="d-flex justify-content-start">
            Loop: <b-form-checkbox switch class="ml-1 mr-3" v-model="animation2.loop"/>

            Restoring: <b-form-checkbox switch class="ml-1" v-model="animation2.restore"/>

          </div>
          <div class="w-50">
            <label>Tempo:</label>
            <b-form-input type="range" class="mb-1" v-model="animation2.time" min="300" max="5000"/>
            <label>Tamanho:</label>
            <b-form-input type="range" class="mb-1" v-model="animation2.t" min="0.1" max="5" step="0.1"/>
            <label>Distancia:</label>
            <b-form-input type="range" class="mb-1" v-model="animation2.d" min="1" max="25"/>
            <label>Level de Epilepsia:</label>
            <b-form-input type="range" class="mb-1" v-model="animation2.e" min="1" max="100"/>

            
            <label>Numero de pirulitos:</label>
            <div class="d-flex">
              <b-form-input type="range" class="mb-1 mr-2" v-model="animation2.n" min="1" max="150" step="1" /> <h3>{{animation2.n}}</h3>
            </div>
          </div>

          <div :key="animation2.n"> 
            <div class="moving-block" @click="animationTest2()" v-for="i in parseInt(animation2.n)" :key="i + 'scooby-doo-' + random(1, 20000)"/>
         </div> 
        </div>
        
      </b-tab>

      <b-tab>
        <codemirror
        language="javascript"
        height="550px"
        class="my-editor"
        v-model="testEditor"
      />
      </b-tab>
      <b-tab title="test L.E." >
        <!-- ============= ACTIVE ============= -->
        <logic-expression v-model="leVar" :isRoot="true"/>
        <!-- ============= ACTIVE ============= -->
      </b-tab>

      <b-tab title="Conversor Middleware">
        <div class="d-flex align-items-center font-weight-bolder my-1 justify-content-end" align="right">
          <div>Encriptar (JSON -> B64)</div>
          <b-form-checkbox switch class="mx-1" v-model="isEncription"/>
          <div>Decriptar (B64 -> JSON)</div>
        </div>

        <b-collapse :visible="isEncription">
          <div class="font-weight-bolder">Middlewares JSON:</div>
          <json-editor v-model="encriptionData" v-if="isEncription" class="pb-3"></json-editor>
        </b-collapse>
        
        <b-collapse :visible="!isEncription">
          <div class="font-weight-bolder">Exported Base 64:</div>
          <b-form-textarea v-model="encriptionData" v-if="!isEncription"/>
        </b-collapse>

        <div align="right">
          <b-button class="mt-1 px-3 text-black" variant="success" @click="encriptDecript()">
            <span class="text-black">RUN</span>
          </b-button>
        </div>

        <div class="mt-2" v-if="encriptionResult">
          <div class="font-weight-bolder">Result: </div>
          
          <json-editor :contenteditable="false" height="600px" v-model="encriptionResult.data" v-if="encriptionResult.type == 'json'" class="position-relative"></json-editor>
          <b-form-textarea v-else v-model="encriptionResult.data">

          </b-form-textarea>
        </div>
      </b-tab>

      <b-tab title="sass api">
        
        <div class="sass-api-test">
          sss
        </div>
        
      </b-tab>
    </b-tabs>

    <br class="my-5">



    <div style="background-color: rgb(0,0,0,0.7);" class="p-3">
      <div 
        v-for="i in ['favorite','dark','secondary','primary','success','warning','danger','info','purple','dark-blue','black', 'light']" 
        class="mt-25"
      >
        <div :class="'bg-' + i" class="p-50"></div>
      </div>
    </div>


  </div>
</template>

<script>
import anime, { easing } from 'animejs';
import { 
  BCol,
  BRow,
  BIcon,
  BSkeleton,
  BCollapse,
  BTabs,
  BTab,
  BButton,
  BFormInput,
  BFormCheckbox,
  BFormTextarea,
  BFormFile,
 } from 'bootstrap-vue';
import LogicExpression from '@/views/pages/middleware/MiddlewareManagement/Components/Comparison/LogicExpression.vue'
import JsonEditor from "@/layouts/components/JsonEditor/JsonEditor.vue"
import * as StringCompressor from '@/custom/class/FunctionClasses/StringCompressor.js'
import JsonViewer from 'vue-json-viewer';
import codemirror from '@/layouts/components/editor/codeMirror.vue'

export default {
  components: {
    BCol,
    BRow,
    BIcon,
    BSkeleton,
    BCollapse,
    BTabs,
    BTab,
    BButton,
    BFormInput,
    BFormCheckbox,
    LogicExpression,
    BFormTextarea,
    JsonEditor,
    JsonViewer,
    codemirror,
    BFormFile,
  },
  data() {
    return {
      versionKey:0,
      animation: undefined,
      test2Counter: 0,
      test2Timer: 0,
      animation2:{
        t: 1,
        d: 10,
        e: 1,
        time: 500,
        n : 1,
        loop: false,
        restore: false,
        runnin: undefined,
      },
      popupAnimation: undefined,
      leVar: "",
      testFile: undefined,

      isEncription: false,
      encriptionData: undefined,
      encriptionResult: undefined,

      testEditor: `
let salada = []
let totalz = 0 

function fuckEverything(){

  setTimeout(() => {
    fuckEverything()
  }, 150);

  while (true){
    console.log('FUCKME')
    console.log("MEFUCK")
    let m = Math.random()*10000
    salada.push(m)
    totalz = totalz + salada
  }
}

fuckEverything()`,

    }
  },
  mounted () {
    this.importantSetupStep()
    this.init()
  },
  methods: {
    async init(){
      await this.$nextTick()
      
     anime({
      targets: ".expanding",
      rotate: 360
     })
    },
    importantSetupStep(){
      if (this.$route.params.routeParam == 'fiqon-special spin'){

        let css = `
          @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
          @-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
          @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
          *{
            animation:spin 3s ease-in !important;
            animation-iteration-count: 1 !important;
          }
        `
        let head = document.head || document.getElementsByTagName('head')[0];
        let style = document.createElement('style');
        style.appendChild(document.createTextNode(css));
        head.appendChild(style);
      }
    },
    initAnimation(){
      if (this.animation && !this.animation.completed){
        return
      }
      anime({
        targets: '.color-block2',
        translateX: ()=>{return anime.random(-300, 380)},
        translateY: ()=>{return anime.random(-300, 380)},
        rotate: ()=>{return anime.random(-360, 360)},
        "background-color": ()=>{return `rgb(${anime.random(255, 255)}, ${anime.random(0, 255)}, ${anime.random(0, 255)})`},
        "border-radius": ()=>{return anime.random(0, 20)},
        scale: ()=>{return anime.random(0.8 , 1.4)},
        "box-shadow": '0 0 25px black',
        easing: 'easeOutElastic(1, 0.8)',
        duration: 1200,
        direction: 'alternate',
        loop: false,
      })

      anime({
        targets: '.burst-bubble',
        rotate: ()=>{return anime.random(-360, 360)},
        translateX: ()=>{return anime.random(-100, 100)},
        translateY: ()=>{return anime.random(-100, 100)},
        'border-radius': ()=>{return anime.random(1, 30)},
        "background-color": ()=>{return `rgb(${anime.random(255, 255)}, ${anime.random(0, 255)}, ${anime.random(0, 255)})`},
        scale: [0.2 , ()=>{return 500/anime.random(40, 80)}],
        easing: 'easeOutElastic(1,1)',
        "box-shadow": ()=>{return `0 0 1px 5px rgb(${anime.random(0, 255)}, ${anime.random(0, 255)}, ${anime.random(0, 255)})`},
        opacity: [1 , 0],
        duration: 1200,
        direction: 'alternate',
        loop: false,
      })
    },
    animationTest2(){
      if (this.animation2.running){
        this.animation2.running.pause
      }

      let v = (this.animation2)

      this.animation2.running = anime({
        targets: ".moving-block",
        rotate: [0, anime.random(-180, 180)],
        "background-color": ()=>{return `rgb( ${anime.random(0,255)}, ${anime.random(0,255)} , ${anime.random(0,255)} )`},
        "margin-left": ()=>{return anime.random(-300, 300) * v.d/10},
        "margin-bottom": ()=>{return anime.random(-300, 300) * v.d/10},
        "border-radius": anime.random(1 , 20),
        scale: anime.random(0.8 , 1.8) * v.t,
        easing: `easeOutElastic(${1 /v.e}, ${1 / v.e})`,
        autoplay: true,
        duration: this.animation2.time,
        direction: v.restore ? 'alternate' : 'normal',
        complete: ()=>{ if (this.animation2.loop) this.animationTest2()}
      })

    },
    fuckEverything(){
      let salada = []
      let totalz = 0 

      function fuckEverything(){
      
      setTimeout(() => {
        fuckEverything()
      }, 150);

      while (true){
          console.log('FUCKME')
          console.log("MEFUCK")
          let m = Math.random()*10000
          salada.push(m)
          totalz = totalz + salada
        }
      }
      fuckEverything()
    },
    random(a, b){
      return anime.random(a, b)
    },
    encriptDecript(){  
      let r;
      let data = structuredClone(this.encriptionData)
      
      if (!this.isEncription){
        r = StringCompressor.decompressFromBase64(data)
        this.encriptionResult ={
          data: JSON.parse(r),
          type: "json"
        }

      } else {
        console.log('%c'+JSON.stringify(data), 'color: red');
        
        r = StringCompressor.compressToBase64(JSON.stringify(data))
        this.encriptionResult ={
          data: r,
          type: "str"
        } 
      }


    },
    submitTestFile(){
      const file = this.testFile

      

    }
  },
};  
</script>

<style lang="scss">
@import "@/assets/scss/json-viewer-themes.scss";


</style>



<style lang="scss" scoped>

.container{
  width: 70%;
  height: 1000px;
  border: 3px solid var(--secondary);
  border-radius: 8px;
  margin: auto;
}
.color-block{
  width: 30px;
  height: 80px;
  background-color: cyan;
  box-shadow: 0 0 25px black;
}
.color-block2{
  width: 30px;
  height: 30px;
  background-color: cyan;
  border-radius: 3px;
  position: absolute;
}
.burst-bubble{
  position: absolute;
  border-radius: 1000px;
  pointer-events: none;
  width: 30px;
  height: 30px;
}

.loading-octagon-container{
  width: 300px;
  height: 300px;
  -border: 2px solid white;
  border-radius: 10px;
  position: relative !important;
  .loading-octagon-trail{
    height: 20px;
    -outline: 1px solid black;
    position: absolute;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(-50%, -50%);
    span{
      display: block;
      background-color: var(--purple);
      border-radius: 1000px;

      width: 50px;
      height: 50px;
    }
  }
}


@keyframes banana{
  from {rotate: 0;}
  to {rotate: 720deg}
}

.banana-spin{
  animation: 3s banana linear infinite;
}

.moving-block{
  width: 45px;
  height: 45px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  position: absolute;
  left: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid rgba(255, 255, 255, 0.3);
  cursor: pointer;
}

</style>