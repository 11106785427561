export default class EnumSources {
  constructor() {
    this.items = [
      {
        "id": 1,
        "type": "middleware",
        "label": "REQUEST",
        "key": "REQUEST",
        "fields": ["HEADER", "BODY", "QUERY", "URI", "METHOD", "COOKIE", "CERTIFICATE"],
        "info": 'enum.middleware.agent_source.request.source_info',
      },
      {
        "id": 2,
        "type": "request",
        "label": "RESPONSE",
        "key": "RESPONSE",
        "fields": [],
        "info": 'enum.middleware.agent_source.response.source_info',
      },
      {
        "id": 3,
        "type": "data",
        "label": "RETURN",
        "key": "RETURN",
        "fields": ["BODY", "HEADER", "CODE"],
        "info": 'enum.middleware.agent_source.return.source_info',
      },
      {
        "id": 4,
        "type": "data",
        "label": "DATA",
        "key": "DATA",
        "fields": ["BODY", "HEADER",  "METHOD", "PATH", "QUERY"],
        "info": 'enum.middleware.agent_source.data.source_info',
      },      
      {
        "id": 5,
        "type": "middleware",
        "label": "THROW",
        "key": "THROW",
        "fields": ["MESSAGE"],
        "info": 'enum.middleware.agent_source.throw.source_info',
      },
      {
        "id": 6,
        "type": "middleware",
        "label": "EVENT",
        "key": "EVENT",
        "fields": ["SYNC", "MERGE", "DATA"],
        "info": 'enum.middleware.agent_source.event.source_info',
      },  
      {
        "id": 7,
        "type": "data",
        "label": "LITERAL",
        "key": "LITERAL",
        "fields": [],
        "info": 'enum.middleware.agent_source.literal.source_info',
      }, 
      { 
        "id": 8,
        "type": "data",
        "label": "INFO",
        "key": "INFO",
        "fields": ["IP", "SERVICE", "REQUEST_ID", "ERROR", "ENV"],
        "info": 'enum.middleware.agent_source.info.source_info',
      },  
      {
        "id": 9,
        "type": "middleware",
        "label": "MONGO",
        "key": "MONGO",
        "fields": ["FILTER", "DOCUMENT", "COLLECTION", "CONNECTION"],
        "info": 'enum.middleware.agent_source.mongo.source_info',
      },  
      { 
        "id": 10,
        "type": "data",
        "label": "TEMP_1",
        "key": "TEMP_1",
        "fields": [],
        "info": 'enum.middleware.agent_source.temp1.source_info',
      }, 
      { 
        "id": 11,
        "type": "data",
        "label": "TEMP_2",
        "key": "TEMP_2",
        "fields": [],
        "info": 'enum.middleware.agent_source.temp2.source_info',
      }, 
      { 
        "id": 12,
        "type": "data",
        "label": "TEMP_3",
        "key": "TEMP_3",
        "fields": [],
        "info": 'enum.middleware.agent_source.temp3.source_info',
      },  
      { 
        "id": 13,
        "type": "middleware",
        "label": "MYSQL",
        "key": "MYSQL",
        "fields": [ "QUERY", "COLUMN", "VALUE",  "ORDER", "LIMIT", "WHERE", "CONNECTION"],
        "info": 'enum.middleware.agent_source.mysql.source_info',
      },  
      { 
        "id": 14,
        "type": "middleware",
        "label": "RATE_LIMITER",
        "key": "RATE_LIMITER",
        "fields": ["TOKEN", "BURST", "KEY"],
        "info": 'enum.middleware.agent_source.rate_limiter.source_info',
      },
      {
        "id": 15,
        "type": "middleware",
        "label": "FOR",
        "key": "FOR",
        "fields": ["FROM", "TO", "INDEX_NAME", "STEP"],
        "info": 'enum.middleware.agent_source.for.source_info',
      },  
      { 
        "id": 16,
        "type": "middleware",
        "label": "FTP",
        "key": "FTP",
        "fields": ["PATH", "FILE_NAME", "CONNECTION"],
        "info": 'enum.middleware.agent_source.ftp.source_info',
      },  
      { 
        "id": 17,
        "type": "middleware",
        "label": "SOAP",
        "key": "SOAP",
        "fields": ["BODY", "HEADER", "FAULT"],
        "info": 'enum.middleware.agent_source.soap.source_info',
      },  
      { 
        "id": 18,
        "type": "middleware",
        "label": "SWITCH",
        "key": "SWITCH",
        "fields": [],
        "info": 'enum.middleware.agent_source.switch.source_info',
      },  
      { 
        "id": 19,
        "type": "middleware",
        "label": "FOREACH",
        "key": "FOREACH",
        "fields": ["WHERE", "INDEX_NAME"],
        "info": 'enum.middleware.agent_source.foreach.source_info',
      },  
      { 
        "id": 20,
        "type": "middleware",
        "label": "SHEETS",
        "key": "SHEETS",
        "fields": ["SHEET_ID", "ACTION", "RANGE", "VALUE", "PAGE_ID"],
        "info": 'enum.middleware.agent_source.google_sheets.source_info',
      },  
      { 
        "id": 21,
        "type": "middleware",
        "label": "POSTGRESSQL",
        "key": "POSTGRESSQL",
        "fields": [ "QUERY", "WHERE", "COLUMN", "VALUE", "ORDER", "LIMIT", "CONNECTION"],
        "info": 'enum.middleware.agent_source.postgres_sql.source_info',
      },  
      { 
        "id": 22,
        "type": "middleware",
        "label": "SIGNER",
        "key": "SIGNER",
        "fields": [ "CERTIFICATE", "FILE_NAME", "ELEMENT" ],
        "info": 'enum.middleware.agent_source.signer.source_info',
      },
      { 
        "id": 23,
        "type": "middleware",
        "label": "FIREBIRD",
        "key": "FIREBIRD",
        "fields": ["CONNECTION", "QUERY"],
        "info": 'enum.middleware.agent_source.firebird.source_info',
      },
      {
        "id": 24,
        "type": "data",
        "label": "ENV",
        "key": "ENV",
        "fields": [],
        "info": 'enum.middleware.agent_source.env.source_info',
      },
      {
        "id": 25,
        "type": "middleware",
        "label": "CRYPTOGRAPHY",
        "key": "CRYPTOGRAPHY",
        "fields": ['CRYPTO_TYPE', 'PUBLIC_KEY', 'MESSAGE'],
        "info": 'enum.middleware.agent_source.cryptography.source_info',
      },
      {
        "id": 26,
        "type": "middleware",
        "label": "DECRYPT",
        "key": "DECRYPT",
        "fields": ['CRYPTO_TYPE', 'ENCRYPT_MESSAGE', 'NONCE', 'PUBLIC_KEY'],
        "info": 'enum.middleware.agent_source.decrypt.source_info',
      },
      {
        "id": 27,
        "type": "middleware",
        "label": "SQL_SERVER",
        "key": "SQL_SERVER",
        "fields": [ "QUERY", "CONNECTION"],
        "info": 'enum.middleware.agent_source.sql_server.source_info',
      },
      {
        "id": 28,
        "type": "middleware",
        "label": "SYBASE",
        "key": "SYBASE",
        "fields": ["QUERY", "CONNECTION"],
        "info": 'enum.middleware.agent_source.sybase.source_info',
      },
      {
      "id": 29,
      "type": "middleware",
      "label": "WAIT",
      "key": "WAIT",
      "fields": ["TIME"],
      "info": 'enum.middleware.agent_source.wait.source_info',
      },
      {
        "id": 30,
        "type": "middleware",
        "label": "ORACLE",
        "key": "ORACLE",
        "fields": ["QUERY", "CONNECTION"],
        "info": 'enum.middleware.agent_source.oracle.source_info',
      },
    ];
  }
}